import { constants } from "../redux/constants";

export const CANADA_SUBJECTS = {
    beforeSignUp: {
        title: 'Before Sign Up',
        imageSrc: '/images/support/account.svg',
        groups:
            [
                {
                    title: 'Get a Plan',
                    topics: [
                        { title: 'Which plans are available at PhoneBox?', articleId: 37, country: constants.CANADA },
                        { title: 'PhoneBox plans have a lock-in contract?', articleId: 40, country: constants.CANADA },
                        { title: 'How does a Prepaid Plan work?', articleId: 9, country: constants.CANADA },
                        { title: 'Does a prepaid line expire?', articleId: 59, country: constants.CANADA },
                        { title: 'How does a Monthly Plan work?', articleId: 8, country: constants.CANADA },
                        { title: 'What is the difference between prepaid and monthly accounts?', articleId: 13, country: constants.CANADA },
                        { title: 'Is my device compatible with PhoneBox network?', articleId: 66, country: constants.CANADA },
                        { title: 'Can I sign up for a PhoneBox plan if I am under 18 years old?', articleId: 68, country: constants.CANADA },
                        { title: 'Why should I choose PhoneBox over other mobile providers?', articleId: 39, country: constants.CANADA },
                    ]
                },
                {
                    title: 'SIM Card',
                    topics: [
                        { title: 'How can a traveler who just arrived in Canada purchase a SIM card?', articleId: 4, country: constants.CANADA },
                        { title: 'Is it possible to purchase a SIM card outside of Canada?', articleId: 5, country: constants.CANADA },
                        { title: 'Does PhoneBox offer shipping of SIM cards?', articleId: 70, country: constants.CANADA },
                        { title: 'Is it possible to schedule the activation date of the SIM card?', articleId: 6, country: constants.CANADA },
                        { title: 'How do I start using my PhoneBox SIM card?', articleId: 10, country: constants.CANADA },
                    ]
                },
                {
                    title: 'eSIM',
                    topics: [
                        { title: 'What is eSIM? ', articleId: 60, country: constants.CANADA },
                        { title: 'What plans are available with eSIM at PhoneBox? ', articleId: 61, country: constants.CANADA },
                        { title: 'Are all devices compatible with eSIM?', articleId: 63, country: constants.CANADA },
                    ]
                },
                {
                    title: 'Phone Number',
                    topics: [
                        { title: 'Can I choose my phone number when I sign up?', articleId: 69, country: constants.CANADA },
                        { title: 'How will I know my phone number?', articleId: 64, country: constants.CANADA },
                        { title: 'Can I port-in a landline phone number to PhoneBox?', articleId: 77, country: constants.CANADA },
                    ]
                }
            ]
    },
    generalInformation: {
        title: 'General Information',
        imageSrc: '/images/support/plans.svg',
        groups:
            [
                {
                    title: 'Activation',
                    topics: [
                        { title: 'Can I change my service\'s start date with PhoneBox?', articleId: 65, country: constants.CANADA },
                        { title: 'How does the online activation work?', articleId: 3, country: constants.CANADA },
                        { title: 'When will PhoneBox deliver my SIM card?', articleId: 36, country: constants.CANADA },
                        { title: 'How does eSIM activation work? ', articleId: 62, country: constants.CANADA },
                        { title: 'Can I port in my phone number from my previous mobile carrier after I already have my PhoneBox\'s account activated? ', articleId: 76, country: constants.CANADA },
                    ]
                },
                {
                    title: 'Data',
                    topics: [
                        { title: 'What is data overage fee and how does it work?', articleId: 67, country: constants.CANADA },
                        { title: 'Will I get a notification if I exceed my data limit?', articleId: 56, country: constants.CANADA },
                    ]
                },
                {
                    title: 'Plan Change',
                    topics: [
                        { title: 'Can I upgrade or downgrade my plan?', articleId: 28, country: constants.CANADA },
                        { title: 'Is it possible to change the plan anytime?', articleId: 27, country: constants.CANADA },
                        { title: 'Does PhoneBox charge a fee to change my plan?', articleId: 18, country: constants.CANADA },
                    ]
                },
                {
                    title: 'Termination',
                    topics: [
                        { title: 'How do I terminate my account?', articleId: 31, country: constants.CANADA },
                        { title: 'Can I schedule my account termination date?', articleId: 32, country: constants.CANADA },
                        { title: 'Will I get a refund for unused services when I terminate my account?', articleId: 33, country: constants.CANADA },
                        { title: 'When will I get my refund after terminating my account?', articleId: 34, country: constants.CANADA },
                        { title: 'Can I still receive calls and SMS after my prepaid plan has expired?', articleId: 57, country: constants.CANADA },
                        { title: 'Will I get a notification when my prepaid plan is about to expire?', articleId: 55, country: constants.CANADA },
                    ]
                },
            ]
    },
    managingYourAccount: {
        title: 'Managing your Account',
        imageSrc: '/images/support/twenty_four_seven.svg',
        groups:
            [
                {
                    title: 'Sign Up',
                    topics: [
                        { title: 'Register for an online account', articleId: 158, country: constants.CANADA },
                        { title: 'What are the services and options available on Self-Serve?', articleId: 200, country: constants.CANADA },
                    ]
                },
                {
                    title: 'Extension',
                    topics: [
                        { title: 'How do I extend my service under the prepaid plan?', articleId: 202, country: constants.CANADA },
                        { title: 'I forgot to extend my prepaid plan before term ending date. How can I make a payment?', articleId: 15, country: constants.CANADA },
                        { title: 'Is it possible to set auto-extension for my prepaid plan?', articleId: 50, country: constants.CANADA },
                    ]
                },
                {
                    title: 'Data',
                    topics: [
                        { title: 'How do I add more data to my prepaid plan?', articleId: 58, country: constants.CANADA },
                        { title: 'How do I check my data usage?', articleId: 165, country: constants.CANADA },
                    ]
                },
                {
                    title: 'Plan Change',
                    topics: [
                        { title: 'Change your plan', articleId: 164, country: constants.CANADA },
                        { title: 'How can I change my plan from prepaid to monthly?', articleId: 29, country: constants.CANADA },
                        { title: 'How can I change my plan from monthly to prepaid?', articleId: 30, country: constants.CANADA },
                    ]
                },
                {
                    title: 'Multiline',
                    topics: [
                        { title: 'What is Multiline Accounts?', articleId: 78, country: constants.CANADA },
                        { title: 'What are the benefits of using a Multi-lining account function?', articleId: 79, country: constants.CANADA },
                        { title: 'How to add a line on your online account using the Multi-lining option? ', articleId: 80, country: constants.CANADA },
                        { title: 'How to remove a line on your online account under the Multi-lining option? ', articleId: 81, country: constants.CANADA },
                    ]
                }
            ]
    },
    services: {
        title: 'Services',
        imageSrc: '/images/support/services.svg',
        groups:
            [
                {
                    title: 'Payment Methods',
                    topics: [
                        { title: 'What are the payment methods for prepaid plans?', articleId: 51, country: constants.CANADA },
                        { title: 'What are the payment methods for monthly plans?', articleId: 52, country: constants.CANADA },
                    ]
                },
                {
                    title: 'Coverage',
                    topics: [
                        { title: 'How good is the PhoneBox network coverage in Canada?', articleId: 38, country: constants.CANADA },
                        // { title: 'Does PhoneBox offer international calling? ', articleId: 0, country: constants.CANADA }, // no article id in spreadsheet, commented for now
                    ]
                },
                {
                    title: 'Vacation Plan',
                    topics: [
                        { title: 'What is a Vacation Plan and how does it work?', articleId: 12, country: constants.CANADA },
                        { title: 'Can I extend my vacation plan?', articleId: 14, country: constants.CANADA },
                    ]
                },
                {
                    title: 'Home Internet',
                    topics: [
                        { title: 'Who is the Home Internet main provider?', articleId: 211, country: constants.CANADA },
                        { title: 'How to know if I\'m eligible to sign up?', articleId: 212, country: constants.CANADA },
                        { title: 'What\'s the available coverage?', articleId: 213, country: constants.CANADA },
                        { title: 'Are there any restrictions when signing up for this Home Internet Plan?', articleId: 214, country: constants.CANADA },

                    ]
                },
                {
                    title: 'International Calling',
                    topics: [
                        { title: 'Does PhoneBox offer International Calling?', articleId: 216, country: constants.CANADA },
                        { title: 'How many countries can I call out to with my International calling plans?', articleId: 217, country: constants.CANADA },
                    ]
                }
            ]
    },
    billingAndPayment: {
        title: 'Billing & Payment',
        imageSrc: '/images/support/billing_payment.svg',
        groups:
            [
                {
                    title: 'Declined Card',
                    topics: [
                        { title: 'PhoneBox notified me that my registered card was declined. What should I do?', articleId: 204, country: constants.CANADA },
                    ]
                },
                {
                    title: 'Billing',
                    topics: [
                        { title: 'What is the billing cycle for prepaid plans?', articleId: 53, country: constants.CANADA },
                        { title: 'What is the billing cycle for monthly plans?', articleId: 54, country: constants.CANADA },
                        { title: 'Am I able to set up automatic payments?', articleId: 162, country: constants.CANADA },
                    ]
                },
            ]
    },
    support: {
        title: 'Support',
        imageSrc: '/images/support/support_information.svg',
        groups:
            [
                {
                    title: 'Shipping',
                    topics: [
                        { title: 'How can I track my SIM card delivery?', articleId: 42, country: constants.CANADA },
                        { title: 'Why have I not received my SIM card yet?', articleId: 41, country: constants.CANADA },
                        { title: 'Why is my SIM card delivery delayed?', articleId: 44, country: constants.CANADA },
                    ]
                },
                {
                    title: 'Service Issues',
                    topics: [
                        { title: 'Troubleshooting options available on the mobile device.', articleId: 201, country: constants.CANADA },
                        { title: 'My phone shows “No Service”. ', articleId: 16, country: constants.CANADA },
                        { title: 'I can\'t send text messages.', articleId: 48, country: constants.CANADA },
                        { title: 'Why can\'t I receive text messages?', articleId: 49, country: constants.CANADA },
                        { title: 'I can\'t make outgoing calls.', articleId: 46, country: constants.CANADA },
                        { title: 'I can\'t receive calls', articleId: 47, country: constants.CANADA },
                        { title: 'I can\'t log into my online account. It shows an error.', articleId: 203, country: constants.CANADA },
                    ]
                },
                {
                    title: 'Port Out',
                    topics: [
                        { title: 'How do I transfer my phone number to a different mobile service provider?', articleId: 35, country: constants.CANADA },
                    ]
                },
                {
                    title: 'Customer Support Team',
                    topics: [
                        { title: 'How to reach out to PhoneBox Customer Support team? ', articleId: 149, country: constants.CANADA },
                        { title: 'Why there is no reply to my emails?', articleId: 45, country: constants.CANADA },
                    ]
                }
            ]
    }
};

export const USA_SUBJECTS = {
    beforeSignUp: {
        title: 'Before Sign Up',
        imageSrc: '/images/support/account.svg',
        groups:
            [
                {
                    title: 'Get a Plan',
                    topics: [
                        { title: 'What is the PhoneBox\'s US prepaid plan?', articleId: 99, country: constants.USA },
                        { title: 'How do I sign up for a US prepaid plan at PhoneBox?', articleId: 101, country: constants.USA },
                    ]
                },
                {
                    title: 'SIM Card',
                    topics: [
                        { title: 'Is it possible to purchase a SIM card outside of the USA?', articleId: 198, country: constants.USA },
                        { title: 'I don\'t have a SIM card yet. How do I sign up for a service with PhoneBox?', articleId: 184, country: constants.USA },
                    ]
                },
                {
                    title: 'Phone Number',
                    topics: [
                        { title: 'Can I choose my phone number when I sign up?', articleId: 197, country: constants.USA },
                        { title: 'Am I able to transfer my existing phone number to PhoneBox?', articleId: 128, country: constants.USA },
                    ]
                },
                {
                    title: 'Activation',
                    topics: [
                        { title: 'How to activate my US prepaid plan?', articleId: 100, country: constants.USA },
                        { title: 'How to activate PhoneBox service under eSIM? ', articleId: 183, country: constants.USA },
                    ]
                }
            ]
    },
    generalInformation: {
        title: 'General Information',
        imageSrc: '/images/support/plans.svg',
        groups:
            [
                {
                    title: 'Plan Change',
                    topics: [
                        { title: 'What is the difference between plan change and term extension? ', articleId: 104, country: constants.USA },
                        { title: 'If I request for a plan change, when will the new plan begin?', articleId: 121, country: constants.USA },
                        { title: 'Is there a deadline for plan change requests?', articleId: 122, country: constants.USA },
                    ]
                },
                {
                    title: 'Termination',
                    topics: [
                        { title: 'Do I get a notification when my prepaid plan is about to expire?', articleId: 118, country: constants.USA },
                        { title: 'How do I terminate my account?', articleId: 112, country: constants.USA },
                        { title: 'Am I able to remove my requested termination?', articleId: 127, country: constants.USA },
                        { title: 'Am I able to reactivate my account after termination?', articleId: 126, country: constants.USA },
                        { title: 'Can I continue using my SIM card after my term expires? ', articleId: 185, country: constants.USA },
                    ]
                },
            ]
    },
    managingYourAccount: {
        title: 'Managing your Account',
        imageSrc: '/images/support/twenty_four_seven.svg',
        groups:
            [
                {
                    title: 'Sign Up',
                    topics: [
                        { title: 'How to Sign-Up to use Self-Serve?', articleId: 191, country: constants.USA },
                        { title: 'What are the services and options available on Self-Serve?', articleId: 193, country: constants.USA },
                    ]
                },
                {
                    title: 'Extension',
                    topics: [
                        { title: 'How do I extend my service under the US prepaid plan?', articleId: 102, country: constants.USA },
                        { title: 'Can I pre-schedule my prepaid extension?', articleId: 105, country: constants.USA },
                        { title: 'Can I set up auto-extension?', articleId: 115, country: constants.USA },
                    ]
                },
                {
                    title: 'Data',
                    topics: [
                        { title: 'How can I top up more data?', articleId: 108, country: constants.USA },
                        { title: 'How do I check my data usage?', articleId: 117, country: constants.USA },
                    ]
                },
                {
                    title: 'Plan Change',
                    topics: [
                        { title: 'How can I change my plan? ', articleId: 103, country: constants.USA },
                        { title: 'Am I able to cancel a requested plan change?', articleId: 125, country: constants.USA },
                    ]
                }
            ]
    },
    services: {
        title: 'Services',
        imageSrc: '/images/support/services.svg',
        groups:
            [
                {
                    title: 'Payment Methods',
                    topics: [
                        { title: 'What are the accepted payment methods? ', articleId: 107, country: constants.USA },
                        { title: 'Can I use an international credit card to pay for a US phone number?', articleId: 114, country: constants.USA },
                    ]
                },
                {
                    title: 'Data',
                    topics: [
                        { title: 'What happens if I reach the data limit?', articleId: 189, country: constants.USA },
                    ]
                },
                {
                    title: 'International Calling',
                    topics: [
                        { title: 'Does PhoneBox offer international calling? ', articleId: 109, country: constants.USA },
                        { title: 'List of countries for International Calling ', articleId: 110, country: constants.USA },
                        { title: 'International Calling: calling minutes Top-Up', articleId: 170, country: constants.USA },
                    ]
                },
            ]
    },
    billingAndPayment: {
        title: 'Payment',
        imageSrc: '/images/support/billing_payment.svg',
        groups:
            [
                {
                    title: 'Payment Methods',
                    topics: [
                        { title: 'What are the available payment methods for term extension? ', articleId: 187, country: constants.USA },
                        { title: 'What are the payment methods available for data top up? ', articleId: 186, country: constants.USA },
                        { title: 'PhoneBox notified me that my registered card was declined. What should I do?', articleId: 194, country: constants.USA },
                    ]
                },
                {
                    title: 'Billing',
                    topics: [
                        { title: 'Should I receive a monthly invoice under my prepaid plan? ', articleId: 106, country: constants.USA },
                        { title: 'I forgot to extend my services and my term ended. How can I make a payment?', articleId: 123, country: constants.USA },
                        { title: 'Am I able to set up automatic payments?', articleId: 129, country: constants.USA },
                        { title: 'Can I get a refund on the data top up? ', articleId: 188, country: constants.USA },
                    ]
                },
            ]
    },
    support: {
        title: 'Support',
        imageSrc: '/images/support/support_information.svg',
        groups:
            [
                {
                    title: 'Shipping',
                    topics: [
                        { title: 'What if I still have not received my SIM on the expected delivery date?', articleId: 137, country: constants.USA },
                    ]
                },
                {
                    title: 'Service Issues',
                    topics: [
                        { title: 'Troubleshooting options available on the mobile device.', articleId: 199, country: constants.USA },
                        { title: 'My phone shows “No Service”. ', articleId: 134, country: constants.USA },
                        { title: 'I can\'t send text messages.', articleId: 135, country: constants.USA },
                        { title: 'I can\'t make outgoing calls.', articleId: 136, country: constants.USA },
                        { title: 'I can\'t log into my online account. It shows an error.', articleId: 195, country: constants.USA },
                    ]
                },
                {
                    title: 'Port Out',
                    topics: [
                        { title: 'How do I transfer my phone number to a different mobile service provider?', articleId: 116, country: constants.USA },
                    ]
                },
                {
                    title: 'Customer Support Team',
                    topics: [
                        { title: 'How to reach out to PhoneBox Customer Support team? ', articleId: 205, country: constants.USA },
                        { title: 'Why there is no reply to my emails?', articleId: 206, country: constants.USA },
                    ]
                }
            ]
    }
};

export const getAllSupportTopics = () => {
    const topics = [];

    // canada
    for (var g of CANADA_SUBJECTS.beforeSignUp.groups) {
        for (var t of g.topics) {
            topics.push(t);
        }
    }

    for (var g of CANADA_SUBJECTS.generalInformation.groups) {
        for (var t of g.topics) {
            topics.push(t);
        }
    }

    for (var g of CANADA_SUBJECTS.managingYourAccount.groups) {
        for (var t of g.topics) {
            topics.push(t);
        }
    }

    for (var g of CANADA_SUBJECTS.services.groups) {
        for (var t of g.topics) {
            topics.push(t);
        }
    }

    for (var g of CANADA_SUBJECTS.billingAndPayment.groups) {
        for (var t of g.topics) {
            topics.push(t);
        }
    }

    for (var g of CANADA_SUBJECTS.support.groups) {
        for (var t of g.topics) {
            topics.push(t);
        }
    }

    // us
    for (var g of USA_SUBJECTS.beforeSignUp.groups) {
        for (var t of g.topics) {
            topics.push(t);
        }
    }

    for (var g of USA_SUBJECTS.generalInformation.groups) {
        for (var t of g.topics) {
            topics.push(t);
        }
    }

    for (var g of USA_SUBJECTS.managingYourAccount.groups) {
        for (var t of g.topics) {
            topics.push(t);
        }
    }

    for (var g of USA_SUBJECTS.services.groups) {
        for (var t of g.topics) {
            topics.push(t);
        }
    }

    for (var g of USA_SUBJECTS.billingAndPayment.groups) {
        for (var t of g.topics) {
            topics.push(t);
        }
    }

    for (var g of USA_SUBJECTS.support.groups) {
        for (var t of g.topics) {
            topics.push(t);
        }
    }
    return topics;
};