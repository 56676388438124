import React, { useState, useEffect } from "react";
import { Image, Card } from "react-bootstrap";
import AppSupportCardGroup from "./AppSupportCardGroup";

interface AppSupportCardProps {
  subject: any;
  open: boolean;
  onClick: Function;
}

const AppSupportCard: React.FC<AppSupportCardProps> = (props: AppSupportCardProps) => {
  const [mobile, setMobile] = useState(false);

  useEffect(() => {
    const handleWindowResize = () => {
      if (window.innerWidth < 769) {
        setMobile(true);
      } else setMobile(false);
    };
    handleWindowResize();
    window.addEventListener("resize", handleWindowResize);

    return () => window.removeEventListener("resize", handleWindowResize);
  }, [mobile]);

  return (
    <Card className="mb-4 justify-content-center" style={{ border: 'none', borderRadius: '7px', boxShadow: '0px 3px 6px #00000029', minHeight: '174px' }}>
      {props.open &&
        <div className="d-flex justify-content-center">
          <div style={{ backgroundColor: '#00faff', width: '100px', height: '5px' }}></div>
        </div>
      }
      <div className="d-flex justify-content-center align-items-center flex-column p-4" onClick={() => props.onClick()} style={{ cursor: 'pointer' }}>
        <Image className="mb-2" width={mobile ? "50" : "70"} height={mobile ? "50" : "70"} src={props.subject.imageSrc}></Image>
        <p className="mb-0 fs-6 fw-bold text-center">{props.subject.title}</p>
      </div>
      {props.open &&
        <>
          {props.subject.groups.map((g, i) => {
            return (<AppSupportCardGroup key={i} group={g} />)
          })}
        </>
      }
    </Card>
  );
}

export default AppSupportCard;